<template>
  <div
    class="relative w-full h-full overflow-hidden"
    @mouseover="playVideo"
    @mouseleave="pauseVideo"
  >
    <img
      :src="imageSrc"
      alt="Background Image"
      class="w-full h-full object-cover"
    />
    <video
      ref="video"
      :src="videoSrc"
      class="absolute top-0 left-0 w-full h-full object-cover"
      loop
      muted
    ></video>
  </div>
</template>

<script setup>
const props = defineProps({
  imageSrc: {
    type: String,
    required: true,
  },
  videoSrc: {
    type: String,
    required: true,
  },
});

const video = ref(null);

const playVideo = () => {
  if (video.value) {
    video.value.play();
  }
};

const pauseVideo = () => {
  if (video.value) {
    video.value.pause();
    video.value.currentTime = 0; // Reset video to the beginning
  }
};
</script>

<style scoped>
/* Ensure the video is hidden initially */
video {
  display: none;
}

/* Show the video on hover */
div:hover video {
  display: block;
}
</style>
